import axios from 'axios';

const apiClient = {
  getRedirectUrl: async function (provider, endpoint="login",acctype, callBackPath, sendInfoPlain) {
    return axios.get(`/${endpoint}`, {
      params: {
        provider: provider,
        callBackPath: callBackPath, 
        sendInfoPlain: sendInfoPlain, 
        acctype:acctype

      },
    });
  },

  // Sign-in function
  signIn: async function (payload) {
    try {
      const response = await this.getRedirectUrl(
        payload.provider, 
        payload.endpoint, 
        payload.acctype,
        payload.callBackPath, 
        payload.sendInfoPlain
      );

      if (response.data.authorizationUrl) {
        console.log("Redirecting to:", response.data.authorizationUrl);
        window.location.href = response.data.authorizationUrl; 
      } else {
        console.error('Authorization URL not found in the response.');
      }
    } catch (error) {
      console.error(`Error initiating ${payload.provider} sign-in:`, error.message);
    }
  },
};

export default apiClient;
