<template>
    <div class="container">
      <div class="row mt-4">

        <div class="col-12 col-lg-6 border-end">
          <div class="text-center"><h1 class="fw-light">Benvenuto in <span class="fw-bold">ALMA!</span></h1></div>
          <div class="col-6 col-lg-12 mx-auto"><img class="img-fluid" src="../../public/img/Template_Linkedin_Soluta.png"></div>
        </div>
      
        <div class="col-12 col-lg-6 px-4 pb-4">

          <form>
            <h1 class="fw-light">Sei già iscritto?</h1>
            <h1 class="fw-bold">Effettua il login!</h1>

            <div class="mb-3">
              <label class="form-label">Username</label>
              <input v-model="username" type="text" id="username">
            </div>
            <div class="mb-3">
              <label class="form-label">Password</label>
              <input type="password" id="password" v-model="password">
              <span v-if="showerror" class="text-danger">Credenziali errate</span>
            </div>
            <button v-on:click.prevent="doLogin">Accedi</button>
          </form>

          OR 
          <div class="container">
            <!-- Custom Google Sign-In Button -->
            <a class="custom-google-signin" v-on:click.prevent="handleProviderSignIn('google','docente')">
              <img src="https://upload.wikimedia.org/wikipedia/commons/1/18/Gile_use.png"
              style="width: 40px; height: auto;" alt="Google Logo">
            </a>
            
            <!-- Custom Apple Sign-In Button -->
            <a class="custom-apple-signin" v-on:click.prevent="handleProviderSignIn('apple', 'docente')">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
              style="width: 40px; height: auto;" alt="Apple Logo">
            </a>
          </div>        
        <div class="row py-4">
          <div class="form-group">
            <span v-on:click.prevent="goto">Password dimenticata? <u>Premi qui</u></span>
          </div>
        </div>

        </div>
          
      </div>
    </div>
</template>

<script>
  export default {
    name: 'HomeLog',
    data() {
    return {
      username: '',
      password: '',
      showerror: false,
      showblocked: false
    };
  },
  created() {},
    methods: {
      doLogin: function() {
        var self=this;
        var data={ username: this.username, password: this.password };
        self.showerror=false;
        self.showblocked=false;
        this.$store.dispatch('loginDocenti', data).then(function() {
          if (self.$store.state.connection.userdata && self.$store.state.blocked === false) {
            var data2={ username: self.$store.state.connection.username, crypt: self.$store.state.connection.userdata };
            self.$store.dispatch('getProfile', data2)
            .then(function() {
              console.log('profile recoveder');
              window.location='/#/Home';
              self.$emit('logged');
            });
          } else if(!self.$store.state.connection.userdata && self.$store.state.blocked !== true) {
            self.showerror=true;
          } else if(self.$store.state.blocked === true){
            self.showblocked=true;
          } 
        })
      },
      goto: function() {
        this.$router.push({ name: 'InvioEmailRecovery' });
      },

    handleProviderSignIn: function (provider,accType) {
      let payload = {provider:provider,acctype:accType,callBackPath:"/Home"}
      
      this.$store.dispatch('signInWithProvider', payload)
    },
    }
  }
</script>
<style scoped>
/* Container */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}

/* Google Sign-In Button */
.custom-google-signin {
  padding: 12px 24px;
  background-color: #4285F4;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 120px;
  transition: all 0.3s ease;
}

.custom-google-signin:hover {
  background-color: #357AE8;
  transform: translateY(-2px);
}

/* Apple Sign-In Button */
.custom-apple-signin {
  padding: 12px 24px;
  background-color: #000000;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 120px;
  transition: all 0.3s ease;
}

.custom-apple-signin:hover {
  background-color: #333333;
  transform: translateY(-2px);
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.login-button img {
  height: 20px;
  margin-right: 10px;
}

.login-button:hover {
  background-color: #f5f5f5;
}

.apple-button img {
  height: 24px;
  /* Slightly larger for Apple logo */
  margin: 0;
  /* No margin for Apple button */
}
</style>